<template>
  <div class="home">
    <div class="home-heard row">
      <div class="heard-left">
        <div class="left-title-model space-between">
          <div class="model-item">
            <div class="text-main font-bold" style="margin-left: 30px">我的人才</div>
            <div class="space-between model-peopl">
              <div class="direction pointer" @click="navTo('/layout/manage')">
                <span>待筛选人才</span>
                <div class="text-blue" v-if="homeObj">
                  {{ homeObj.push_num ? homeObj.push_num : 0 }}
                </div>
              </div>
              <div class="direction pointer" @click="navTo('/layout/manage', 3)">
                <span>收藏的人才</span>
                <div class="text-blue" v-if="homeObj">
                  {{ homeObj.collect_num ? homeObj.collect_num : 0 }}
                </div>
              </div>
            </div>
          </div>
          <div class="model-item">
            <div class="text-main font-bold" style="margin-left: 30px">我的职位</div>
            <div class="space-between model-peopl">
              <div class="direction pointer" @click="navTo('/layout/positionRelease', 0)">
                <span>在线职位</span>
                <div class="text-blue" v-if="homeObj">
                  {{ homeObj.position_num ? homeObj.position_num : 0 }}
                </div>
              </div>
              <div class="direction pointer" @click="navTo('/layout/positionRelease', 0)">
                <span>7天内即将下线</span>
                <div class="text-blue" v-if="homeObj">
                  {{ homeObj.failure_num ? homeObj.failure_num : 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="font-bold text-main">我的面试</div>
        <div class="table-center">
          <el-table :data="tableData" max-height="646" style="width: 100%">
            <el-table-column
              prop="interview_time"
              label="面试时间"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="position_name" label="职位" width="180" align="center">
            </el-table-column>
            <el-table-column prop="name" label="候选人" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.user.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="hr_phone" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待接受</span>
                <span v-if="scope.row.status == 1">已接受</span>
              </template>
            </el-table-column>
            <!-- 没有数据的时候 -->
            <template slot="empty">
              <div class="empty-img">
                <img src="../../../assets/glxt/26.png" alt="" />
                <span>暂无数据</span>
              </div>
            </template>
          </el-table>
        </div>
        <div class="pagination-div">
          <el-pagination
            @current-change="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="sizeChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 我的资产 -->
      <div class="heard-right">
        <div class="title space-between">
          <span class="text-main font-lighter">我的资产</span>
          <span class="font-normal pointer" @click="navTo('/layout/assets')">更多</span>
        </div>
        <div class="space-between tight-text">
          <div class="direction">
            <span>人才推荐</span>
            <span v-if="accountObj">{{
              accountObj.push_num ? accountObj.push_num : 0
            }}</span>
          </div>
          <div class="direction">
            <span>纺织豆</span>
            <span v-if="accountObj">{{ accountObj.coin ? accountObj.coin : 0 }}</span>
          </div>
        </div>
        <div class="right-button flex-center text-blue font-normal">
          <div class="flex-center pointer" @click="navTo('/layout/assets')">购买职豆</div>
        </div>
        <div class="right-num-text space-between">
          <div class="direction">
            <span class="text-main" v-if="accountObj">{{
              accountObj.refresh_num ? accountObj.refresh_num : 0
            }}</span>
            <div>刷新次数</div>
          </div>
          <div class="direction">
            <span class="text-main" v-if="accountObj">{{
              accountObj.top_num ? accountObj.top_num : 0
            }}</span>
            <div>置顶天数</div>
          </div>
          <div class="direction">
            <span class="text-main" v-if="accountObj">{{
              accountObj.recruit_num ? accountObj.recruit_num : 0
            }}</span>
            <div>急招天数</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 活动专区 -->
    <div class="Activity-home" v-if="operateList.length > 0">
      <div class="title text-main font-bold">活动专区</div>
      <div class="img-category space-between" >
        <div class="category-left direction-between pointer">
          <img :src="operateList[0].img" alt="" @click="linkTo(0)" />
          <img :src="operateList[4].img" alt="" v-if="operateList.length == 5" @click="linkTo(4)" />
        </div>
        <div class="category-righe direction-between pointer">
          <img :src="operateList[1].img" alt="" v-if="operateList.length >= 2" @click="linkTo(1)" />
          <img :src="operateList[2].img" alt="" v-if="operateList.length >= 3" @click="linkTo(2)" />
          <img :src="operateList[3].img" alt="" v-if="operateList.length >= 4" @click="linkTo(3)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/user";
import recApi from "../../../api/recruiter";
import toolApi from "../../../api/tool";
export default {
  components: {},

  data() {
    return {
      tableData: [],
      accountObj: null,
      total: 0,
      query: {
        page: 1,
        pageSize: 10,
        is_home:1
      },
      homeObj: null,
      operateList: [],
    };
  },

  created() {
    this.userAccount();
    this.getInterviewList();
    setTimeout(() => {
      this.getHomeToal();
    }, 500);
    this.activityList();
  },

  mounted() {},

  methods: {
    linkTo(index){
      this.$router.push({path:"/layout/activity",query:{id:this.operateList[index].id}})
    },
    activityList() {
      toolApi.operateList().then((res) => {
        this.operateList = res.data.data;
      });
    },
    getHomeToal() {
      recApi.getHomeToal().then((res) => {
        this.homeObj = res.data;
      });
    },
    currentPage(val) {
      this.query.page = val;
      this.getInterviewList();
    },
    sizeChange(val) {
      this.query.pageSize = val;
      this.getInterviewList();
    },
    getInterviewList() {
      recApi.getInterviewList(this.query).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    navTo(path, id) {
      this.$router.push({ path, query: { id } });
    },
    // 获取用户资产
    userAccount() {
      api.userAccount().then((res) => {
        if (res.code == 200) {
          this.accountObj = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  padding: 1.25rem 8.125rem 1.875rem 9.0625rem;
  .home-heard {
    margin-bottom: 0.625rem;
    flex-wrap: nowrap;
    .heard-left {
      width: 69.75rem;
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      padding: 1.875rem 2.125rem 1rem 2.1875rem;
      .left-title-model {
        margin-bottom: 2.6875rem;
        .model-item {
          width: 31.75rem;
          height: 11.375rem;
          background: #ffffff;
          box-shadow: 0px 1px 8px 3px rgba(221, 221, 221, 0.38);
          border-radius: 1px;
          padding-top: 1.3125rem;
          .model-peopl {
            margin-top: 2.5rem;
            justify-content: space-around;
            > div {
              color: #222;
              font-size: 1.0625rem;
              div {
                font-size: 1.875rem;
                line-height: 2;
              }
            }
          }
        }
      }
      .table-center {
        width: 100%;
        height: 30.6875rem;
        margin-top: 2rem;
        /deep/.el-table--fit {
          max-width: 82.6875rem;
          height: 30.6875rem !important;
        }
        /deep/.el-table__body-wrapper {
          max-height: calc(30.6875rem - 48px) !important;
        }
        /deep/.el-table th.el-table__cell {
          background: #f9f9f9;
        }
        .empty-img {
          height: 30.375rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 205px;
            height: 182px;
            margin-bottom: 20px;
          }
        }
      }
      .pagination-div {
        margin-top: 1.25rem;
      }
    }
    .heard-right {
      width: 17.75rem;
      height: 100%;
      padding: 1.375rem 1.375rem 1.3125rem 1.1875rem;
      background: #ffffff;
      box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
      margin-left: 0.625rem;
      .title {
        color: #757575;
        margin-bottom: 20px;
      }
      .tight-text {
        justify-content: space-around;
        color: #444444;
        font-size: 13px;
        div {
          span:last-child {
            line-height: 2.5;
          }
        }
      }
      .right-button {
        margin: 16px 0;
        div {
          width: 230px;
          height: 34px;
          border: 1px solid #126bf9;
        }
      }
      .right-num-text {
        justify-content: space-evenly;
        color: #8d92a1;
        font-size: 0.8125rem;
        span {
          font-size: 15px;
          display: inline-block;
          line-height: 1.5;
        }
      }
    }
  }
  // 活动专区
  .Activity-home {
    width: 1116px;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    padding: 1.25rem 2.1875rem 3.25rem 2.25rem;
    .img-category {
      width: 100%;
      margin-top: 1.875rem;
      height: 26.625rem;
      .category-left {
        margin-right: 3px;
        img {
          width: 697px;
          height: 140px;
          margin-bottom: 3px;
        }
        img:last-child {
          height: 283px;
        }
      }
      .category-righe {
        img {
          width: 363px;
          height: 140px;
          margin-bottom: 3px;
        }
      }
    }
  }
}
</style>
